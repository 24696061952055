import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import NavBar from '../components/NavBar.vue';

//Banners:
import PrayerBanner from '../components/PrayerBanner.vue';
import SiteBanner from '../components/SiteBanner.vue';
import ArchivesBanner from '../components/ArchivesBanner.vue';
import EventsBanner from '../components/EventsBanner.vue';
import AboutUsBanner from '../components/AboutUsBanner.vue';

//Components/Views:
import Archives from '../components/Archives.vue';
import Events from '../components/Events.vue';
import EventView from '../components/EventView.vue';
import AboutUs from '../components/AboutUs.vue';
import PrayerRequest from '../components/PrayerRequest.vue';
import Prayer from '../components/Prayer.vue';

const routes = [
	{
		path: '/',
		name: 'home',
		components: {
			nv: NavBar,
			banner: SiteBanner,
			default: HomeView,
		},
	},
	{
		path: '/archives',
		name: 'archives',
		components: {
			nv: NavBar,
			banner: ArchivesBanner,
			default: Archives,
		},
	},
	{
		path: '/events',
		name: 'events',
		components: {
			nv: NavBar,
			banner: EventsBanner,
			default: Events,
		},
	},
	{
		path: '/events/:q',
		components: {
			nv: NavBar,
			default: EventView,
		},
		props: true,
	},
	{
		path: '/prayer',
		name: 'prayer',
		components: {
			nv: NavBar,
			banner: PrayerBanner,
			default: PrayerRequest,
		},
	},
	{
		path: '/prayers/:token',
		components: {
			nv: NavBar,
			default: Prayer,
		},
		props: true,
	},
	{
		path: '/about',
		name: 'about',
		components: {
			nv: NavBar,
			banner: AboutUsBanner,
			default: AboutUs,
		},
	},
	// {
	// 	path: '/about',
	// 	name: 'about',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: function () {
	// 		return import(/* webpackChunkName: "about" */ '../views/AboutView.vue');
	// 	},
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
