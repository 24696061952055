<template>
	<div class="columns">
		<div class="column is-full">
			<img class="banner-img" :src="MainBanner" />
		</div>
	</div>
</template>

<script>
	import MainBanner from '../assets/media/banner_main.png';
	export default {
		data() {
			return {
				MainBanner: MainBanner,
			};
		},
	};
</script>

<style scoped>

    .banner-img{
        border-radius: 7px;
    }
</style>
