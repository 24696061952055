<template>
	<div class="container mt-5 mb-5">
		<h2 class="title" align="center">Archivos</h2>
		<div class="columns is-mobile pl-3 pr-3">
			<div class="is-quarter">
				<aside class="menu">
					<p class="menu-label">Sagas</p>
					<ul class="menu-list">
						<li>
							<a
								v-for="cat in categories"
								:class="cat.catName === selectedCat ? 'is-active' : ''"
								href="#"
								:key="cat.catId"
								@click="onCatClick(cat.catName)"
								>{{ cat.catName }}</a
							>
						</li>
					</ul>
				</aside>
			</div>
			<div class="column ml-5">
				<div class="box">
					<div class="columns is-multiline w-100 p-2 pt-3 pb-3">
						<div
							class="column is-one-third"
							v-for="archive in archives"
							:key="archive.aid"
						>
							<div class="card">
								<div class="card-image is-hidden">
									<figure class="image">
										<img
											class="card-img"
											src="https://via.placeholder.com/300"
											:alt="archive.title"
										/>
									</figure>
								</div>
								<p class="card-header-title">
									{{ archive.title }}
								</p>

								<div class="card-content">
									<div class="content">
										<p align="justify">
											{{
												`${new Date(
													archive.publishedDate,
												).toLocaleDateString()}`
											}}
										</p>
										<p align="start">
											{{ archive.description }}
										</p>
									</div>
								</div>
								<div class="card-footer">
									<a
										class="card-footer-item"
										:href="
											archive.isVideo
												? archive.videoUrl
												: `/archives/${archive.aid}`
										"
										:target="archive.isVideo ? '_blank' : '_self'"
										>Escuchar</a
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ArchiveStore } from '../store/archives';

	export default {
		data() {
			return {
				categories: [],
				archives: [],
				selectedCat: '',
			};
		},
		beforeMount() {
			const me = this;
			const archStore = ArchiveStore();
			return archStore
				.getCategories('Archive')
				.then((cat) => {
					me.categories = cat;
					``;
					return;
				})
				.then(() => {
					//Always auto-select the top category/Saga:
					if (me.categories.length > 0) {
						me.selectedCat = me.categories[0].catName;
						return archStore.getArchives(me.selectedCat).then((a) => {
							debugger;
							me.archives = [];
							me.archives = a;
						});
					} else {
						return;
					}
				});
		},
		// watch: {
		// 	selectedCat(newVal) {
		// 		debugger;
		// 		return newVal;
		// 	},
		// },
		methods: {
			onCatClick(catId) {
				const me = this;
				const archStore = ArchiveStore();

				debugger;
				return archStore.getArchives(catId).then((a) => {
					debugger;
					me.selectedCat = catId;
					me.archives = [];
					me.archives = a;
				});
			},
		},
	};
</script>

<style scoped>
	.card-img {
		max-height: 150px;
	}
</style>
