import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

//CSS Framework:
// import 'bulma/css/bulma.min.css';
import 'bulma/css/bulma.css';

const pinia = createPinia();

createApp(App).use(pinia).use(router).mount('#app');
