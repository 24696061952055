import axios from 'axios';
import { defineStore } from 'pinia';

export const PrayerStore = defineStore('prayerStore', {
	actions: {
		async submitPrayer(prayers) {
			if (prayers === undefined || prayers.length === 0) return false;

			return await axios
				.post('/api/v1/prayer', prayers)
				.then((r) => {
					var dat = r.data;

					return dat.success;
				})
				.catch(() => false);
		},
		async getPrayer(reqTkn) {
			if (reqTkn === undefined || reqTkn === null || reqTkn.length === 0)
				return [];

			return axios
				.get(`/api/v1/prayer?pId=${reqTkn}`)
				.then((r) => {
					var rsp = r.data;
					return rsp.success ? rsp.msg : [];
				})
				.catch(() => []);
		},
		async prayerReply(prayer) {
			if (prayer === undefined || prayer === null)
				return { msg: '', success: false };

			return await axios
				.post('/api/v1/prayer/reply', prayer)
				.then((r) => {
					var rsp = r.data;
					console.log(rsp);
					return rsp;
				})
				.catch((e) => {
					return { msg: e, success: false };
				});
		},
	},
});
