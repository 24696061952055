<template>
	<div class="container">
		<div class="columns">
			<div class="column is-half">
				<div class="card">
					<header class="card-header has-text-centered">
						<p class="card-header-title">Enseñanzas</p>
					</header>
					<div class="card-content">
						<div class="box" v-for="a in archives" :key="a.aid">
							<div align="start">
								<b>{{ new Date(a.publishedDate).toLocaleDateString() }}</b>
								&nbsp;
								<a
									class="section-link"
									:href="a.isVideo ? a.videoUrl : a.htmlContent"
									:target="a.isVideo ? '_blank' : '_self'"
									>{{ a.title }}<br />
								</a>
							</div>
						</div>
					</div>
					<p class="card-footer">
						<a href="/archives" class="card-footer-item is-primary">Ver Mas</a>
					</p>
				</div>
			</div>
			<div class="column is-half">
				<div class="card">
					<header class="card-header">
						<p class="card-header-title" align="center">Anuncios</p>
					</header>

					<div class="card-content">
						<div class="box" v-for="a in announcements" :key="a.evid">
							<div align="start">
								<b>{{ new Date(a.eventDate).toLocaleString() }}</b>
								&nbsp;
								<a
									class="section-link"
									:href="a.isVideo ? a.videoUrl : `/events/${a.slug}`"
									:target="a.isVideo ? '_blank' : '_self'"
									>{{ a.title }}<br />
								</a>
							</div>
						</div>
					</div>

					<p class="card-footer">
						<a href="/events" class="card-footer-item is-primary">Ver Mas</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ContentStore } from '../store/content';

	export default {
		data() {
			return {
				archives: [],
				announcements: [],
			};
		},
		beforeMount() {
			const me = this;
			const cStore = ContentStore();

			return cStore.getSections().then((s) => {
				me.archives = s.archives;
				me.announcements = s.events;
				return;
			});

			// return cStore
			// 	.getArchives()
			// 	.then((a) => {
			// 		debugger;

			// 		me.archives = a;
			// 		return;
			// 	})
			// 	.then(() => {
			// 		return cStore.getEvents().then((e) => {
			// 			me.announcements = e;
			// 			return;
			// 		});
			// 	});
		},
	};
</script>

<style>
	.footer-item:hover {
		border-radius: 5px;
		background: #f14e23;
		color: #fff;
	}
</style>
