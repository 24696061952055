import { defineStore } from 'pinia';
import axios from 'axios';

export const ContentStore = defineStore('ContentStore', {
	actions: {
		async getArchives(q = undefined) {
			q = q !== undefined ? `?q=${q}` : '';

			return axios
				.get(`/api/v1/archives${q}`)
				.then((r) => {
					var dat = r.data;

					if (dat.success) return dat.msg;
					else return [];
				})
				.catch(() => []);
		},
		async getEvents(q = undefined) {
			q = q !== undefined ? `?q=${q}` : '';

			return axios
				.get('/api/v1/events')
				.then((rsp) => {
					var dat = rsp.data;

					if (dat.success) return dat.msg;
					else return [];
				})
				.catch(() => []);
		},
		async getSections() {
			return axios
				.get('/api/v1/sections')
				.then((rsp) => {
					var dat = rsp.data;

					if (dat.success) return dat.msg;
					else return {};
				})
				.catch(() => {});
		},
		async getAboutCards() {
			return axios
				.get('/api/v1/about')
				.then((rsp) => {
					var dat = rsp.data;
					return dat.success ? dat.msg : [];
				})
				.catch(() => []);
		},
	},
});
