<template>
	<div class="container">
		<div class="columns" v-for="card in aboutCards" :key="card.auid">
			<div class="is-quarter">
				<img
					:src="`data:image/png;base64, ${card.img}`"
					v-if="
						card.img !== undefined && card.img !== null && card.img.length > 0
					"
				/>
				<img src="https://dummyimage.com/400x400/fff/000" v-else />
			</div>
			<div class="column">
				<p class="title">{{ card.title }}</p>
				<div v-html="card.htmlContent"></div>
			</div>
		</div>
		<footer>
			<nav class="navbar">
				<div class="navbar-menu">
					<div class="navbar-start">
						<a
							class="navbar-item"
							href="https://maps.apple.com/?daddr=145 NY-59, Nanuet, NY 10954"
							target="_blank"
							>Como llegar a RLCC Español</a
						>
						<a class="navbar-item" href="http://www.redeeminglovecc.org/" target="_blank">
							Servicio en Ingles
						</a>
					</div>
				</div>
			</nav>
		</footer>
	</div>
</template>

<script>
	import { ContentStore } from '../store/content';

	export default {
		data() {
			return {
				aboutCards: [],
			};
		},
		beforeMount() {
			const me = this;
			const store = ContentStore();

			return store.getAboutCards().then((r) => {
				debugger;
				me.aboutCards = r;
			});
		},
	};
</script>

<style scoped>
	.brand-img {
		border-radius: 10px;
	}
	.navbar-item {
		min-width: 150px !important;
		max-height: 45px !important;
		color: #000;
	}
	.navbar-item:hover {
		border-radius: 5px;
		background: #f14e23;
		color: #fff;
	}
</style>
