<template>
	<div v-if="!sent">
		<div class="box">
			<h4 class="title is-4">Petición de Oración</h4>
			<p align="start"></p>
			<p align="start">
				{{ prayer.name }} ha sometido la siguiente petición de oración: <br />
				{{ prayer.request }}
			</p>
		</div>
		<section class="section">
			<h4 class="title is-4">Contestar:</h4>
			<textarea
				class="textarea"
				placeholder="Escriba aca..."
				rows="10"
				v-model="prayer.reply"
			>
			</textarea>
		</section>
		<p class="card-footer">
			<a href="#" class="card-footer-item is-primary" @click="onSubmitClick"
				>Submit</a
			>
		</p>
	</div>
	<div v-else>
		<div class="box" v-if="resp.success">
			<div class="content">
				<h3 class="is-3 has-text-success">{{ resp.msg }}</h3>
			</div>
		</div>
		<div class="box" v-else>
			<div class="content">
				<h3 class="is-3 has-text-danger">{{ resp.msg }}</h3>
			</div>
		</div>
	</div>
</template>

<script>
	import { PrayerStore } from '../store/prayer';

	export default {
		props: {
			token: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				prayer: {},
				sent: false,
				resp: {},
			};
		},
		beforeMount() {
			const me = this;
			const gateway = PrayerStore();

			return gateway.getPrayer(me.token).then((r) => {
				me.prayer = r.length > 0 ? r[0] : {};
				return;
			});
		},
		methods: {
			async onSubmitClick(e) {
				debugger;
				e.preventDefault();

				const me = this;
				const gateway = PrayerStore();

				return await gateway.prayerReply(me.prayer).then((r) => {
					debugger;
					me.resp = r;
					me.sent = true;
				});
			},
		},
	};
</script>

<style>
	/* .card-footer-item {
		color: #000;
	} */
	.card-footer-item:hover {
		border-radius: 5px;
		background: #f14e23;
		color: #fff;
	}
</style>
