import { defineStore } from 'pinia';
import axios from 'axios';

export const MenuBar = defineStore('mainBar', {
	actions: {
		async getMenuItems() {
			return axios
				.get('/api/v1/menu')
				.then((rsp) => {
					var dat = rsp.data;

					if (dat.success) return dat.msg;
					else return [];
				})
				.catch(() => []);
		},
	},
});
