import { defineStore } from 'pinia';
import axios from 'axios';

export const ArchiveStore = defineStore('Archive_Store', {
	actions: {
		async getCategories(catType, q = undefined) {
			q = q !== undefined ? `&q=${q}` : '';

			if (catType === undefined || catType.lenth <= 0) return [];

			return axios
				.get(`/api/v1/categories?catType=${catType}${q}`)
				.then((rsp) => {
					var dat = rsp.data;

					return dat.success ? dat.msg : [];
				})
				.catch(() => []);
		},
		async getArchives(q) {
			if (q === undefined || q === '') return [];

			q = `?q=${q}`;

			return axios
				.get(`/api/v1/archives${q}`)
				.then((r) => {
					var dat = r.data;

					return dat.success ? dat.msg : [];
				})
				.catch(() => []);
		},
		async getEvents(params) {
			if (params === undefined) return [];
			// catId, q = undefined, from = undefined, to = undefined

			var catId = '';
			var q = '';

			if (params.catId !== undefined && params.catId.length > 0)
				catId = `?catId=${params.catId}`;

			if (params.q !== undefined && params.q.length > 0)
				q = `${catId.length > 0 ? '&' : '?'}q=${params.q}`;

			//Add all other parameters...
			debugger;
			return axios
				.get(`/api/v1/events${catId}${q}`)
				.then((r) => {
					var dat = r.data;

					return dat.success ? dat.msg : [];
				})
				.catch(() => []);
		},
	},
});
