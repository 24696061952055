<template>
	<div class="card">
		<div
			class="card-image"
			v-if="content.img !== undefined && content.img !== null"
		>
			<figure class="image is-4by3">
				<img :src="`data:image/gif;base64,${content.img}`" />
			</figure>
		</div>
		<div class="card-content">
			<p class="title">{{ content.title }}</p>
			<p class="subtitle" align="justify" style="font-size:12px;">
				{{ `Published: ${new Date(content.publishDate).toLocaleString()}` }}
			</p>
			<div class="content">
				<div v-html="content.htmlContent"></div>
			</div>
		</div>
		<footer class="card-footer">
			<a
				:href="content.videoUrl"
				class="card-footer-item"
				v-if="
					content.videoUrl !== undefined &&
					content.videoUrl !== null &&
					content.videoUrl.length > 0
				"
				target="_blank"
			>
				Mas Detalles
			</a>
			<a
				:href="content.directions"
				class="card-footer-item"
				target="_blank"
				v-if="content.hasDirections"
				>Instrucciones de Direccion</a
			>
		</footer>
	</div>
</template>

<script>
	import { ArchiveStore } from '../store/archives';

	export default {
		props: {
			q: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				content: {},
			};
		},
		beforeMount() {
			const me = this;
			const gateway = ArchiveStore();

			return gateway.getEvents({ q: me.q }).then((r) => {
				debugger;
				if (r.length > 0) me.content = r[0];

				return;
			});
		},
	};
</script>

<style></style>
