<template>
	<div class="columns">
		<div class="column is-full w-100">
			<img class="banner-img" :src="MainBanner" />
		</div>
	</div>
</template>

<script>
	import MainBanner from '../assets/media/rlcc-bg.jpeg';
	export default {
		data() {
			return {
				MainBanner: MainBanner,
			};
		},
	};
</script>

<style scoped>
	.banner-img {
		border-radius: 7px;
		width: 100%;
		max-height:350px;
	}
</style>
