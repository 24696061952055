<template>
	<div class="container">
		<router-view name="nv" />

		<router-view name="banner" />

		<router-view />

		<div class="footer is-justify-content-center">
			<div class="content is-justify-content-center">
				<div class="columns is-mobile">
					<div class="column is-half">
						<a href="/prayer">
							<div class="columns is-mobile">
								<div class="column is-one-quarter">
									<img
										class="footer-img"
										:src="PrayerImg"
										alt="Solicitar oracion."
									/>
								</div>

								<div class="column">
									<p class="footer-menu">Solicitar Oracion</p>
								</div>
							</div>
						</a>
						<!-- <a href="/prayer">
							<div class="media">
								<figure class="media-left">
									<p class="image">
										<img
											class="footer-img"
											:src="PrayerImg"
											alt="Solicitar oracion."
										/>
									</p>
								</figure>

								<div class="media-content mt-3" style="padding-left:0px;">
									<p class="footer-menu">Solicitar Oracion</p>
								</div>
							</div>
						</a> -->
					</div>
					<div class="column is-half">
						<a
							href="https://www.fellowshiponegiving.com/App/Form/442fc92c-a790-4a53-92d0-d61b1b49f9a5"
							target="_blank"
						>
							<div class="columns is-mobile">
								<div class="column is-one-quarter pr-0">
									<img
										class="footer-img"
										:src="TitheImg"
										alt="Diezmos y Ofrendas."
									/>
								</div>
								<div class="column pl-0">
									<p class="footer-menu">Diezmos y Ofrendas</p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <nav-bar /> -->
</template>

<script>
	// import { defineComponent } from '@vue/composition-api';
	import NavBar from './components/NavBar.vue';
	import Tithe from './assets/tithe2.png';
	import Prayer from './assets/prayer3.jpeg';

	export default {
		components: {
			NavBar,
		},
		data() {
			return {
				PrayerImg: Prayer,
				TitheImg: Tithe,
			};
		},
	};

	// export default defineComponent({
	// 	// components: { NavBar },
	// 	setup() {},
	// });
</script>

<style>
	.menu-list a.is-active {
		background: #f14e23 !important;
	}

	.footer-menu {
		font-style: italic;
		vertical-align: middle;
		text-align: start;
		color: #000;
		left: 0;
		margin-left: 0;
		padding-left: 1px;
	}

	.footer-button:hover {
		background: #f14e23;
	}
	.footer-img {
		border-radius: 5px;
		max-height: 50px;
		max-width: 75px;
	}

	.is-primary {
		background: transparent !important;
		color: #000 !important;
	}

	.is-primary:hover {
		background: #f14e23 !important;
		color: #fff !important;
	}

	.section-link {
		/* font-weight: bold; */
		font-style: italic;
		color: #f14e23;
		margin-left: 15px;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	nav {
		padding: 30px;
	}

	nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	nav a.router-link-exact-active {
		color: #42b983;
	}
</style>
