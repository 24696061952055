<template>
	<div class="container mt-5 mb-5">
		<h2 class="title" align="center">Eventos</h2>
		<div class="columns is-mobile pl-3 pr-3">
			<div class="is-quarter">
				<aside class="menu">
					<p class="menu-label">Categoria de Eventos</p>
					<ul class="menu-list">
						<li>
							<a
								v-for="cat in categories"
								:class="cat.catName === selectedEvent ? 'is-active' : ''"
								href="#"
								:key="cat.catId"
								@click="onCatClick(cat.catName)"
							>
								{{ cat.catName }}
							</a>
						</li>
					</ul>
				</aside>
			</div>
			<div class="column ml-5">
				<div class="box">
					<div class="columns is-multiline w-100 p-2 pt-3 pb-3">
						<div
							class="column is-one-third"
							v-for="event in events"
							:key="event.evid"
						>
							<div class="card">
								<div class="card-image">
									<figure class="image is-hidden">
										<img
											class="card-img"
											src="https://via.placeholder.com/300"
											:alt="event.title"
										/>
									</figure>
								</div>
								<p class="card-header-title">
									{{ event.title }}
								</p>
								<div class="card-content">
									<div class="content">
										<p align="justify">
											{{ event.description }}
										</p>
									</div>
								</div>
								<div class="card-footer">
									<a
										class="card-footer-item"
										:href="
											event.isVideo ? event.videoUrl : `/events/${event.slug}`
										"
										:target="event.isVideo ? '_blank' : '_self'"
										>Ver detalles</a
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Apple Maps Addr Query: http://maps.apple.com/?daddr=&dirflg=r -->
		<!-- Google Maps Addr Query: -->
	</div>
</template>

<script>
	import { ArchiveStore } from '../store/archives';

	export default {
		data() {
			return {
				categories: [],
				events: [],
				selectedEvent: '',
			};
		},
		beforeMount() {
			const me = this;
			const gateway = ArchiveStore();

			return gateway
				.getCategories('Events')
				.then((r) => {
					me.categories = r;

					if (r.length > 0) me.selectedEvent = r[0].catName;

					return;
				})
				.then(() => {
					return gateway.getEvents(me.selectedEvent).then((r) => {
						me.events = r;
					});
				});
		},
		methods: {
			onCatClick(catName) {
				const me = this;
				const gateway = ArchiveStore();

				me.selectedEvent = catName;
				return gateway.getEvents(me.selectedEvent).then((r) => {
					me.events = r;

					return;
				});
			},
		},
	};
</script>

<style scoped></style>
