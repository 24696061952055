<template>
	<div class="container" v-if="submitted">
		<div class="columns">
			<div class="column">
				<h5 class="is-success">Su Oración fue recibida.</h5>
				<p align="justify">Le contestaremos pronto!</p>
			</div>
		</div>
	</div>
	<div class="container" v-else>
		<div class="card">
			<header class="card-header">
				<p class="card-header-title">Solicitud de Oración</p>
			</header>
			<div class="card-content">
				<p class="content" align="justify">
					Si esta pasando por tiempos difíciles y siente que sus oraciones no
					son escuchadas. <br />
					O talvez si quiere recivir a Jesús Cristo como Sú Señor y Salvador.
				</p>
				<p class="content" align="justify">Solicite una oración.</p>
				<div class="columns">
					<div class="column is-half">
						<div class="field">
							<div class="control">
								<input
									class="input"
									type="text"
									placeholder="Nombre"
									v-model="name"
								/>
							</div>
						</div>
					</div>
					<div class="column is-half">
						<div class="field">
							<div class="control">
								<input
									class="input"
									type="text"
									placeholder="Número de teléfono"
									v-model="telNo"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="columns">
					<div class="column is-full">
						<div class="field">
							<div class="control">
								<input
									class="input"
									type="text"
									placeholder="Correo electrónico"
									v-model="email"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="columns">
					<div class="column is-full">
						<div class="field">
							<div class="control">
								<textarea
									class="input"
									type="text"
									placeholder="Como nos unimos a Sú oración?"
									v-model="msg"
									style="min-height: 150px"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer class="card-footer">
				<a
					class="card-footer-item is-primary"
					href="#"
					@click="onSubmit($event)"
					>Enviar</a
				>
			</footer>
		</div>
	</div>
</template>

<script>
	import { PrayerStore } from '../store/prayer';

	export default {
		data() {
			return {
				name: '',
				telNo: '',
				email: '',
				msg: '',
				submitted: false,
				success: undefined,
			};
		},
		methods: {
			onSubmit(e) {
				debugger;
				const me = this;
				const gateway = PrayerStore();

				if (
					this.name.length < 1 ||
					this.email.length < 1 ||
					this.telNo.length < 1 ||
					this.msg.length < 1
				)
					return false;

				var prayers = [
					{
						name: this.name,
						email: this.email,
						mobileNo: this.telNo,
						request: this.msg,
					},
				];

				return gateway.submitPrayer(prayers).then((r) => {
					me.submitted = true;
					me.success = r;

					return;
				});
			},
		},
	};
</script>

<style></style>
