<template>
	<div class="home">
		<!-- <img alt="Vue logo" src="../assets/logo.jpg" width="112"> -->
		<home-body />
	</div>
</template>

<script>
	// @ is an alias to /src
	import HomeBody from '@/components/HomeBody.vue';

	export default {
		name: 'HomeView',
		components: {
			HomeBody,
		},
	};
</script>
