<template>
	<nav class="navbar" role="navigation" aria-label="main navigation">
		<div class="navbar-brand">
			<a class="navbar-item" href="/">
				<img class="brand-img" :src="Logo" style="min-height: 75px" />
			</a>

			<a
				role="button"
				class="navbar-burger"
				aria-label="menu"
				aria-expanded="false"
				data-target="navbarBasicExample"
				@click="isMenuActive = !isMenuActive"
			>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>

		<div
			id="navbarBasicExample"
			:class="`navbar-menu ${isMenuActive ? 'is-active' : ''}`"
		>
			<div class="navbar-start">
				<a
					class="navbar-item"
					v-for="mi in MenuItems"
					:href="mi.link"
					:key="mi.name"
					>{{ mi.name }}</a
				>
			</div>

			<div class="navbar-end is-invisible">
				<div class="navbar-item">
					<div class="buttons">
						<a class="button is-primary">
							<strong>Sign up</strong>
						</a>
						<a class="button is-light"> Log in </a>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	import Logo from '../assets/logo.jpg';
	import { MenuBar } from '../store/navbar.js';

	export default {
		data() {
			return {
				Logo: Logo,
				isMenuActive: false,
				MenuItems: [],
			};
		},
		beforeMount() {
			const mb = MenuBar();
			const me = this;

			return mb.getMenuItems().then((m) => {
				me.MenuItems = m;
			});
		},
	};
</script>

<style scoped>
	.brand-img {
		border-radius: 10px;
	}
	.navbar-item {
		min-width: 150px !important;
		max-height: 45px !important;
	}
	.navbar-item:hover {
		border-radius: 5px;
		background: #f14e23;
		color: #fff;
	}
</style>
